<template lang="pug">
.single-order.flex.column.overflow-hidden.gap-8
  dd-button(@click.native="backToList") < 返回
  .tab.flex.column.fill.overflow-overlay(v-if="tab === 1")
    .preview.p-20.flex.align-center.justify-center
      img.preview-img(v-if="data.thumb" :src="data.thumb")
      img.preview-img(v-else src='/user.png')
    .data.flex.align-center.m-20.gap-20
      .columns.flex.column.align-start.gap-8.fill
        .col.flex.align-center.gap-8(
          v-for="(col, idx) in columns", :key="idx"
          v-if="data[col.key] !== ''")
          .title {{ col.text }}
          .value(v-if="col.getter") {{ col.getter(data) }}
          .value(v-else) {{ data[col.key] }}
    .actions.p-20.flex.column.gap-8
      dd-button(@click.native="showPayDialog", v-if="showGiveChange") 找零
      dd-button(@click.native="showPayDialog", v-if="showPay") 收款
      dd-button(@click.native="setPause", v-if="canPause") 暫停
      dd-button(@click.native="setStart", v-if="canContinue") 繼續開始
      dd-button(@click.native="setReserve", v-if="canReserve") 保留
      dd-button(@click.native="setCancel", v-if="canCancel") 取消
      dd-button(@click.native="setFinish", v-if="canFinish", type="primary") 完成訂單
      dd-button(
        @click.native="goContinueReserved",
        v-if="isReadOnly && !data.continued", type="primary") 以此開立新單
  .tab.flex.column.fill.overflow-overlay(v-else-if="tab === 2") WIP
  .tab.flex.column.fill.overflow-overlay(v-else-if="tab === 3")
    .notes.flex.column.gap-8.m-20.fill
      .desc 備注
      .note.flex.align-center.gap-20(v-for="note in data.notes")
        zoomable-image(
          v-if="note.src", :thumb="note.thumb", :src="note.src"
          width="120px", height="120px")
        .texts.flex.column.fill
          .text {{ note.text }}
          template(v-for="(text, idx) in note.texts")
            .text(:key="idx") {{ text }}
      template(v-if="!isReadOnly")
        .desc.mt-20 新增備註
        dd-button(@click.native="upload") 上傳圖片
        input(
          accept="image/*"
          type="file", ref="upload",
          @input="changeFile")
        .flex.align-center.justify-center
          img.preview-img(v-if="previewURL !== ''", :src="previewURL")
        textarea.textarea.p-20(rows="3", v-model="note")
        dd-button(@click.native="addNote", type="primary") 新增
  .tabs.flex.align-center
    dd-button.fill(@click.native="tab = 1", :tab-button="tab === 1") 資訊
    dd-button.fill(@click.native="tab = 2", :tab-button="tab === 2", v-if="!isReadOnly") 其他商品
    dd-button.fill(@click.native="tab = 3", :tab-button="tab === 3") 備註
  md-dialog(:md-active.sync="paidDialogVisible" :md-fullscreen="false")
    md-dialog-title 訂單資訊
    md-dialog-content
      .info.flex.column.justify-center.font-size-14
        p(v-if="showGiveChange") 找零
        p(v-else-if="showPay") 收款
        input(v-model="amount", type="number", min=0)
    md-dialog-actions.flex.justify-end
      md-button.md-primary(@click="savePaid") 確認
      md-button(@click="paidDialogVisible = false") 取消
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  getReserve, addReserveNote, updateStatus, saveReservePayment,
} from '@/api/reserve';
import { uploadImage, getImageThumbPath, getImagePath } from '@/api/image';
import constant from '@/data/constant';

export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      data: {},
      columns: [
        {
          key: 'orderNumber',
          text: '訂單編號',
        },
        {
          key: 'startTime',
          text: '開始時間',
        },
        {
          key: 'pauseTime',
          text: '暫停時間',
        },
        {
          key: 'endTime',
          text: '結束時間',
        },
        {
          key: 'actualEnd',
          text: '離場時間',
        },
        {
          key: 'status',
          text: '訂單狀態',
          getter: (item) => (
            item.continued ? '已使用' : constant.orderStatusToText(item.status, item.end < this.now)),
        },
        {
          key: 'payment',
          text: '支付狀態',
          getter: (item) => constant.paidStatusToText(item.payment),
        },
      ],

      imageID: undefined,
      note: '',
      now: 0,
      tab: 1,

      paidDialogVisible: false,
      amount: 0,
    };
  },
  computed: {
    ...mapGetters(['showMenu', 'headerTitle']),
    previewURL() {
      return this.imageID ? getImageThumbPath(this.imageID) : '';
    },
    canPause() {
      return this.data.status === constant.orderStatus.ReserveStatusInit;
    },
    canContinue() {
      return this.data.status === constant.orderStatus.ReserveStatusPause;
    },
    canCancel() {
      return this.data.status === constant.orderStatus.ReserveStatusInit;
    },
    canReserve() {
      return this.data.status === constant.orderStatus.ReserveStatusInit
        || this.data.status === constant.orderStatus.ReserveStatusPause;
    },
    canFinish() {
      return this.data.status === constant.orderStatus.ReserveStatusInit;
    },
    isReadOnly() {
      return this.data.status === constant.orderStatus.ReserveStatusReserved;
    },
    showGiveChange() {
      return this.data.payment?.status === constant.paidStatus.PaymentStatusNeedChange;
    },
    showPay() {
      return this.data.payment?.status === constant.paidStatus.PaymentStatusPartial;
    },
  },
  methods: {
    ...mapMutations(['setHeaderTitle', 'setContinueOrder']),
    showPayDialog() {
      this.paidDialogVisible = true;
      this.amount = this.data?.payment.paid - this.data?.payment.amount;
      if (this.amount < 0) {
        this.amount *= -1;
      }
    },
    savePaid() {
      this.$execWithLoading(async () => {
        let amount = parseInt(this.amount, 10);
        if (this.showGiveChange) {
          amount *= -1;
        }
        await saveReservePayment(this.data.uuid, amount);
        await this.loadReserveInfo();
        this.paidDialogVisible = false;
      });
    },
    setPause() {
      this.setStatus(constant.orderStatus.ReserveStatusPause);
    },
    setStart() {
      this.setStatus(constant.orderStatus.ReserveStatusInit);
    },
    setReserve() {
      this.setStatus(constant.orderStatus.ReserveStatusReserved);
    },
    setCancel() {
      this.setStatus(constant.orderStatus.ReserveStatusCancel);
    },
    setFinish() {
      this.setStatus(constant.orderStatus.ReserveStatusFinish);
    },
    goContinueReserved() {
      this.setContinueOrder(this.data);
      this.$router.push('/newFishOrder');
    },
    async setStatus(status) {
      this.$execWithLoading(async () => {
        await updateStatus(this.data.uuid, status);
        await this.loadReserveInfo();
      });
    },
    backToList() {
      if (this.data.status === constant.orderStatus.ReserveStatusReserved) {
        this.$router.push('/reserveOrderList');
        return;
      }
      this.$router.push('/orderList');
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadReserveInfo();
      });
    },
    async loadReserveInfo() {
      const rsp = await getReserve(this.id);
      this.data = {
        ...rsp.data,
        startTime: this.$timeToString(rsp.data.start * 1000),
        endTime: rsp.data.pause_start === 0 ? this.$timeToString(rsp.data.end * 1000) : '',
        pauseTime: this.$timeToString(rsp.data.pause_start * 1000),
        actualEnd: this.$timeToString(rsp.data.actual_end * 1000),
        orderNumber: rsp.data.uuid.slice(0, 8),
        src: getImagePath((rsp.data.notes || [])[0]?.photo_id),
        thumb: getImageThumbPath((rsp.data.notes || [])[0]?.photo_id),
        notes: (rsp.data.notes || []).map((n) => ({
          thumb: getImageThumbPath(n.photo_id),
          src: getImagePath(n.photo_id),
          text: n.message,
        })),
      };
    },
    upload() {
      this.$refs.upload.click();
    },
    async changeFile() {
      if (this.$refs.upload.files.length < 1) {
        return;
      }
      this.$execWithLoading(async () => {
        const file = this.$refs.upload.files[0];
        const rsp = await uploadImage(file);
        this.imageID = rsp.data;
      });
    },
    addNote() {
      console.log(this.imageID, this.note);
      this.note = this.note.trim();
      if (!this.imageID && !this.note) {
        this.$showError('備註不能是空的');
        return;
      }
      this.$execWithLoading(async () => {
        await addReserveNote(this.id, {
          photo_id: this.imageID,
          message: this.note,
        });
        await this.loadReserveInfo();
        this.imageID = '';
        this.note = '';
      });
    },
  },
  mounted() {
    this.setHeaderTitle('訂單細節');
    this.loadData();
    this.now = parseInt(new Date().getTime() / 1000, 10);
  },
};
</script>

<style lang="scss" scoped>
.single-order {
  .preview {
    flex: 0 0 180px;
    .preview-img {
      width: 180px;
      height: 180px;
    }
  }
  .notes {
    .note {
      border: 1px solid #ccc;
      flex: 0 0 120px;
      .img {
        flex: 0 0 120px;
        img {
          height: 120px;
          width: 120px;
        }
      }
    }
  }
  .textarea {
    font-size: 20px;
    min-height: 80px;
  }
  input[type=file] {
    display: none;
  }
}
input {
  padding: 4px;
}
</style>
